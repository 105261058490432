import { useEffect, useState } from "react";
import { getMonthName } from "../helpers/dateTimeHelper";

export default function ChangeMonth({ callback }) {
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);

    useEffect(function() {
        var d       = new Date();
        var month   = d.getMonth();
        const year  = d.getFullYear();

        month++;

        setMonth(month);
        setYear(year);
    }, [])

    useEffect(function () {
        return callback(month, year).then(function(result) {
            return result;
        }).catch(function(e) { console.error(e)});
    }, [month, year])

    function goToOtherMonth(context) {

        if (context === "next") {
            if (month === 12) {
                setMonth(1);
                setYear(year + 1);
            } else {
                setMonth(month + 1);
            }
            
            return;
        }

        if (month === 1) {
            setMonth(12);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
        
        return;
    }

    return (
        <div className="row box">
            <div className="row box-body">
                <div className="col-lg-1 text-left">
                    <button
                    className="btn btn-light"
                    onClick={() => goToOtherMonth("previous")}><i className="bi bi-caret-left-fill text-muted"></i></button>{" "}
                    <button
                    className="btn btn-light"
                    onClick={() => goToOtherMonth("next")}
                    ><i className="bi bi-caret-right-fill text-muted"></i></button>
                </div>
                <div className="col-lg-2 text-left btn btn-lg text-bold">
                    {getMonthName(month)} {year}
                </div> 
            </div>             
        </div>
    )
}