import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import { getCalendarStats } from "../api/works";
import { getMonthName } from "../helpers/dateTimeHelper";


export default function Calendar({ toggleModal }) {
    const [isLoading, setIsLoading] = useState(false);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [stats, setStats] = useState([]);

    const history = useHistory();

    useEffect(function() {
        var d       = new Date();
        var month   = d.getMonth();
        const year  = d.getFullYear();

        month++;

        setMonth(month);
        setYear(year);
    }, [])

    useEffect(function () {
        getStats(month, year);
    }, [month, year])

    async function getStats(month, year) {
        setIsLoading(true);
        let stats = await getCalendarStats(month, year);
        setStats(stats);
        setIsLoading(false);
    }

    function goToOtherMonth(context) {

        if (context === "next") {
            if (month === 12) {
                setMonth(1);
                setYear(year + 1);
            } else {
                setMonth(month + 1);
            }
            
            return;
        }

        if (month === 1) {
            setMonth(12);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
        
        return;
      }

    if (isLoading) {
        return (
            <div className="row">
              <div className="col-lg-12 text-center p-5">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="row mt-2 box">
                <div className="box-body text-left row">
                    <div className="col-lg-1 text-left">
                        <button
                        className="btn btn-light"
                        onClick={() => goToOtherMonth("previous")}><i className="bi bi-caret-left-fill text-muted"></i></button>{" "}
                        <button
                        className="btn btn-light"
                        onClick={() => goToOtherMonth("next")}
                        ><i className="bi bi-caret-right-fill text-muted"></i></button>
                    </div>
                    <div className="col-lg-2 text-left btn btn-lg text-bold">
                        {getMonthName(month)} {year}
                    </div>
                </div>    
            </div>
            <div className='box text-left'>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Lundi</h5></div>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Mardi</h5></div>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Mercredi</h5></div>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Jeudi</h5></div>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Vendredi</h5></div>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Samedi</h5></div>
                <div style={{ display: "inline-block", position: "relative", width: "13%", margin: "2px", verticalAlign: "top" }}><h5>Dimanche</h5></div>

                <PreCells datas={stats} />
                <Cells datas={stats} />
            </div>
        </React.Fragment>
    );

    function PreCells(datas) {
        let cells = Object.entries(datas);
        cells = cells[0][1];
        cells = Object.entries(cells);

        if (cells.length > 0) {
            return preCells(cells);
        }

        return (<React.Fragment></React.Fragment>)
    }

    function Cells(datas) {
        let cells = Object.entries(datas);
        cells = cells[0][1];
        cells = Object.entries(cells);

        return cells.map((cell) => {
            return (
                <Cell cell={cell} />
            )
        })
    }

    function Cell(props){
        let index = parseInt(props.cell[0]); 
        const cell = props.cell[1];
        const dayNumber = cell.dayNumber;
        const block = dayNumber === 7;
        const bgColor = (dayNumber === 6 || dayNumber === 7) ? "#DFDFDF" : "#FFF";
        const date = new Date();
        const day = date.getDate();
        const isToday = index === day;
    
        return (
            <React.Fragment>
                <div key={index} onClick={() => toggleModal(true)} className="box-body calendarCell" style={{ backgroundColor: bgColor }}>
                    <div style={{ position: "absolute", top: "5px", left: "10px", backgroundColor: isToday ? "#0d6efd" : "#FFF", borderRadius: 20, padding: "0 8px", color: isToday ? "#FFF" : "#000" }}>
                        {index}
                    </div>
                    <div className="text-left" style={{ height: "-webkit-fill-available",  }}>
                        { cellRender(cell) }
                    </div>
                </div>
                {block && <div style={{ display: "block" }}></div>}
            </React.Fragment>
        );
    }
    
    function cellRender(cell) {
        if (Object.keys(cell).length > 0) {
            return (Object.keys(cell.stats).length > 0 && Object.entries(cell.stats).map((client, index) => (
                <span key={index} style={{ backgroundColor: client[1].color, marginRight: "3px" }} className="badge badge-pill badge-primary mr-1 scale" onClick={() => history.push(`/work/${client[1].id}`)}>{client[1].firstname}</span>
            )))
        }
    
        return <span></span>
        
    }

    function preCells(stats) {
        if (stats.length > 0) {
            if (stats[0][1].dayNumber > 1) {
                return Array.apply(1, Array(stats[0][1].dayNumber - 1)).map(function(stat, i) {
                    return (
                        <div key={i} style={{ display: "inline-block", position: "relative", width: "13%", height: "100px", margin: "2px", backgroundColor: 'transparent', verticalAlign: "top" }} className="box-body">
                            <div className="text-left" style={{ height: "-webkit-fill-available",  }}>
                                
                            </div>
                        </div>) 
                })
            }   
        }
        
        return (<React.Fragment></React.Fragment>)
    }
}