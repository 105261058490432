import config from '../../config.json';
import { clearSession, handleErrors } from './authHelper';
import { getOvertime } from './dateTimeHelper';

export const getStructureStats = async (structure, month, year) => {
    try {
      const token = localStorage.getItem('_k');
  
      const res = await fetch(config[0].apiUrl + "/works.php?q=stats&structure=" + structure + "&month=" + month + "&year=" + year + "&token=" + token);
      const result_1 = await res.json();
      
      return result_1;
    } catch (error) {
        console.log(error);
        
        return;
    }
  }

  /*export const getAllStructures = () => {
    return new Promise(function(resolve, reject) {
      const token = localStorage.getItem('_k');

      return fetch(config[0].apiUrl + "/structures.php?q=all&token=" + token)
        .then(handleErrors)
        .then(res => res.json())
        .then(
          (result) => {
            resolve(result);
          },
          (error) => {
            return clearSession();
          }
        )
      })
  }*/

  export const getStats = (structure, month, year) => {
    try {
      if (month <= 9) {
        month = `0${month}`;
      }

      let strStats = [];
      
      return getStructureStats(structure.uid, month, year).then((stats) => {
        return getOvertime(structure.uid).then((time) => {
          const overtimeToConvert = (time[0].millisecondsWorked / 3600) * 60;
          stats.overtime = overtimeToConvert;

          strStats.push(stats);

          return strStats;
        });
      });
    } catch (error) {
      console.log(error);
      clearSession();

      return;
    }   
  }

  export const getStructuresByCity = (city) => {
    const token = localStorage.getItem('_k');

    return fetch(config[0].apiUrl + "/structures.php?q=filter&filter=city&criteria=" + city + "&token=" + token)
      .then(res => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log(error);

          return;
        }
      )
  }

  export const getAmountByStructure = async (structuresStats) => {
    let monthAmount = 0;
    let weekAmount = 0;

    await structuresStats.forEach(structure => {
      monthAmount += structure.month_work;
      weekAmount += structure.week_work;
    });

    return { weekAmount, monthAmount, isLoadingAmounts: false }
  }