import { fetchApi } from "./api"

export const getWork = async (workId) => {
    return fetchApi('/works.php', 'GET', {
        q: 'id',
        id: workId
    })
}

export const deleteWork = async (workId) => {
    return fetchApi('/works.php', 'POST', {
        action: 'removestat',
        id: workId
    })
}

export const createWork = async (work) => {
    return fetchApi('/works.php', 'POST', work)
}

export const getCalendarStats = async (month, year) => {
    return fetchApi('/works.php', 'GET', {
        q: 'calendarStats',
        month: month,
        year: year
    })
}

export const getHoursTotal = async (month, year) => {
    return fetchApi('/works.php', 'GET', {
        q: 'hourstotal',
        month: month,
        year: year
    })
}

export const saveWork = async (work) => {
    return fetchApi('/works.php', 'POST', work)
}
