import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash-es";
import { Spinner } from 'react-bootstrap';
import { getWork } from "../api/works";
import { useHistory, useParams } from "react-router";
import Moment from "react-moment";
import start from '../../assets/start.png';
import finish from '../../assets/finish.png';
import customer from '../../assets/rating.png';
import money from '../../assets/euro-money.png';
import { workedTimeRender } from "../utils/WorkTimeRender";

function Work() {
    const [isLoading, setIsLoading] = useState(false);
    const [work, setWork] = useState({});
    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        async function initWork() {

            if (Object.keys(params).length > 0) {
                const workId = params.id;

                setIsLoading(true);
                let w = await getWork(workId);
                const wo = w[0];
                setWork(wo);

                const workedTime = workedTimeRender()

                /*if (!isEmpty(wo)) {
                    work.dateStart          = wo.start.substring(0, 10);
                    work.dateEnd            = wo.end.substring(0, 10);
                    work.timeStart          = wo.start.substring(11);
                    work.timeEnd            = wo.end.substring(11);
                    work.structure_id       = parseInt(wo.structure_id);
                    work.description        = wo.description;
                    work.overtime           = parseInt(wo.overtime);
                    work.breakTime          = wo.breakTime;
                    work.price              = wo.price;
                    work.id                 = wo.id;

                    setWork(work);
    
                    
                }*/

                setIsLoading(false);
            }
        }

        initWork();

    }, [])

    if (isLoading) {
        return (
            <div className="row">
              <div className="col-lg-12 text-center p-5">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="row box m-auto mt-2 text-left">
                <h1 className="h4 mb-0 col-lg-12 box-body">Résumé</h1>
            </div>
            <div className="row">
                <div className="col-lg-7 col-md-10 m-auto">
                    <div className="row box-body">
                        <div className="col-lg-5 text-left box">
                            <h3 className="h4 text-muted fw-light">Début</h3>
                            <h4 className="h5 m-0"><Moment locale="fr" format='dddd D MMMM YYYY'>{work.start}</Moment></h4>
                            <h4 className="h5 text-secondary"><Moment locale="fr" format='HH:mm'>{work.start}</Moment></h4> 
                        </div>
                        <div className="col-lg-5 text-left box">
                            <div className="">
                                <h3 className="h4 text-muted fw-light">Fin</h3>
                                <h4 className="h5 m-0"><Moment locale="fr" format='dddd D MMMM YYYY'>{work.end}</Moment></h4>
                                <h4 className="h5 text-secondary"><Moment locale="fr" format='HH:mm'>{work.end}</Moment></h4>
                            </div>    
                        </div>
                        <div className="col-lg-2 text-left box">
                            <div className="text-center">
                                <h3 className="h4 text-muted fw-light">Durée</h3>
                                <h4 className="h4 m-0">
                                    <Moment diff={work.start} unit="hours">{work.end}</Moment>h
                                    <Moment diff={work.start} unit="minutes">{work.end}</Moment>
                                </h4><br />
                            </div>    
                        </div>    
                    </div>
                    <div className="row box-body mt-3">
                        <div className="col-lg-8 text-left">
                            <h3 className="h4 text-muted fw-light">Client</h3>
                            <h4 className="h5">{work.firstname} <span className="fw-light text-muted">{work.lastname}</span></h4>
                            <h4 className="h5">{work.name}</h4>
                            <h4 className="h5">{work.ville_code_postal} {work.ville_nom_reel}</h4>
                        </div>
                        <div className="col-lg-4 text-left box text-center">
                            <h3 className="h4 text-muted fw-light">Gain</h3>
                            <h4 className="h1">{work.price}€</h4><br />  
                        </div>    
                    </div>
                    <div className="row box-body mt-3">
                        <div className="col-lg-12 text-left">
                            <h4 className="h4">Notes</h4>
                            <p className="fst-italic text-justify">
                                {work.description}
                            </p>
                        </div>   
                    </div>
                    <div className="row box-body mt-3">
                        <div
                         className="col-lg-12 text-right">
                            <button className="btn btn-link" onClick={() => history.goBack()}>Retour</button>{" "}
                            <button className="btn btn-primary" onClick={() => history.push('/work/' + work.workId + '/edit')}>Modifier</button>{" "}
                            <button className="btn btn-danger">Supprimer</button>
                        </div>   
                    </div>    
                </div>
            </div>
        </React.Fragment>    
    );
}

export default Work;