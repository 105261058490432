import { fetchApi } from "./api"

export const getAllStructures = async () => {
    return fetchApi('/structures.php', 'GET', {
        q: 'all'
    })
}

export const getStructure = async (uid) => {
    return fetchApi('/structures.php', 'GET', {
        q: 'uid',
        uid: uid
    })
}

export const getAllStructuresStats = async (context, month, year) => {
    return fetchApi('/structures.php', 'GET', {
        q: 'allstats',
        context,
        month,
        year
    })
}

export const saveStructure = async (structure) => {
    return fetchApi('/structures.php', 'POST', structure)
}

export const createStructure = async (structure) => {
    return fetchApi('/structures.php', 'POST', structure)
}

export const deleteStructure = async (structureUid) => {
    return fetchApi('/structures.php', 'POST', {
        uid: structureUid,
        action: 'delete'
    })
}

export const getTotalPrice = async (month, year) => {
    return fetchApi('/structures.php', 'GET', {
        q: 'totalprice',
        month: month,
        year: year
    })
}

