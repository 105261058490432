import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { launchNotification } from "../../../App";
import { searchCities } from "../../api/cities";
import { deleteStructure, getStructure, saveStructure } from "../../api/structures";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router";
import { convertMinutesToHours } from "../../helpers/dateTimeHelper";

function StructureForm({ structureUid = "" }) {

    const [structure, setStructure] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [entreprise, setEntreprise] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [color, setColor] = useState('#336699');
    const [price, setPrice] = useState(0);
    const [action, setAction] = useState('create');
    const [dayHoursTarget, setDayHoursTarget] = useState(0);
    const [dayMinutesTarget, setDayMinutesTarget] = useState(0);
    const [weekHoursTarget, setWeekHoursTarget] = useState(0);
    const [weekMinutesTarget, setWeekMinutesTarget] = useState(0);
    const [monthHoursTarget, setMonthHoursTarget] = useState(0);
    const [monthMinutesTarget, setMonthMinutesTarget] = useState(0);
    const [, setDayTarget] = useState(0);
    const [, setWeekTarget] = useState(0);
    const [, setMonthTarget] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    const history = useHistory();

    useEffect(() => {
        async function initStructure() {
            if (structureUid.length > 0) {
                setIsLoading(true);
                const struct = await getStructure(structureUid);

                if (!isEmpty(struct)) {
                    const dayConverted = convertMinutesToHours(struct[0].day_target);
                    const weekConverted = convertMinutesToHours(struct[0].week_target);
                    const monthConverted = convertMinutesToHours(struct[0].month_target);

                    setStructure(struct[0]);
    
                    setFirstname(struct[0].firstname);
                    setLastname(struct[0].lastname);
                    setEntreprise(struct[0].name);
                    setColor(struct[0].color);
                    setPrice(struct[0].price);
                    setDayTarget(struct[0].day_target);
                    setWeekTarget(struct[0].week_target);
                    setMonthTarget(struct[0].month_target);
                    setDayHoursTarget(dayConverted.hours);
                    setDayMinutesTarget(dayConverted.minutes);
                    setWeekHoursTarget(weekConverted.hours);
                    setWeekMinutesTarget(weekConverted.minutes);
                    setMonthHoursTarget(monthConverted.hours);
                    setMonthMinutesTarget(monthConverted.minutes);
                    setAction('update');
    
                    setIsLoading(false);
                }
            }
        }

        initStructure();

    }, [])

    function setCity(city) {
        let oldStructure = structure;
        oldStructure.ville_id = city.ville_id;
        oldStructure.ville_nom_reel = city.ville_nom_reel + " (" + city.ville_code_postal + ")";
        oldStructure.ville_code_postal = city.ville_code_postal;

        setStructure(oldStructure);
        setCities([]);
    }

    async function handleDelete() {
        if (window.confirm("La suppression du client entrainera la suppression de toutes les heures qui lui sont rattachées. Etes-vous sûr(e) ?")) {
            try {
                setIsDeleting(true);
                await deleteStructure(structureUid);
                history.push('/structures');
            } catch (error) {
                launchNotification('danger', 'Erreur', 'Une erreur est survenue lors de la suppression');
                setIsDeleting(false);
            }   
        }
    }

    async function search(value) {
        const towns = await searchCities(value);
        setCities(towns);
    }

    function handleSubmit(e) {
        e.preventDefault();

        let oldStructure = structure;
        oldStructure.firstname = firstname;
        oldStructure.lastname = lastname;
        oldStructure.entreprise = entreprise;

        setStructure(oldStructure);

        var structureFormData = {
            uid: action === 'create' ? uuidv4() : structure.uid,
            name: entreprise,
            color: color,
            firstname: structure.firstname,
            lastname: structure.lastname,
            city_id: structure.ville_id,
            price: price,
            day_target: parseInt((dayHoursTarget*60)) + parseInt(dayMinutesTarget),
            week_target: parseInt((weekHoursTarget*60)) + parseInt(weekMinutesTarget),
            month_target: parseInt((monthHoursTarget*60)) + parseInt(monthMinutesTarget),
            action: action
        }

        saveStructure(structureFormData).then(function() {
            launchNotification('success', 'Succès', 'Informations enregistrées avec succès.')

            return;
        }).catch(function(e) {
            launchNotification('danger', 'Erreur', 'Une erreur est survenue.')
            console.log(e);

            return;
        });
    }

    function handleColor(e) {
        const color = e.target.value;

        setColor(color);
    }

    const simulateClick = function (elem) {
        // Create our event (with options)
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        var canceled = !elem.dispatchEvent(evt);
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-6 col-md-6 box">
                    <div className="box-body">
                        { structureRender() }
                    </div>    
                </div>
                <div className="col-lg-6 col-md-6 box">
                    <div className="box-body text-left">
                        { colorRender() }
                    </div>
                    {structureUid.length > 0 && <div className="box text-right mt-2">
                        <button className={`btn btn-danger ${isDeleting && "disabled"}`} onClick={handleDelete} type="button">
                            {isDeleting && <React.Fragment><span className="spinner-border spinner-border-sm" role="status" ariaHidden="true"></span>{" "}
                            Suppression</React.Fragment>}
                            {!isDeleting && <React.Fragment>Supprimer</React.Fragment>}
                        </button>
                    </div>}    
                </div>    
            </div>
        </React.Fragment>    
    );

    function colorRender() {
        return (
            <React.Fragment>
                <label htmlFor="color" className="form-label">Couleur</label>
                <div className="w-100 p-4 rounded scale" onClick={() => simulateClick(document.getElementById("colorInput"))} style={{ backgroundColor: color }}></div>
                <input type="color" id="colorInput" className="form-control" value={color} style={{ height: "1px", opacity: 0 }} onChange={handleColor} />
            </React.Fragment>
        );
    }

    function structureRender() {
        if (isLoading) {
            return (<Spinner animation="border" variant="success" />)
        }

        return (
            <form className="row g-3 text-left">
                <div className="col-md-6 pe-2">
                    <label htmlFor="firstname" className="form-label">Prénom</label>
                    <input type="text" className="form-control" id="firstname" name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                </div>
                <div className="col-md-6">
                    <label htmlFor="lastname" className="form-label">Nom</label>
                    <input type="text" className="form-control" id="lastname" name="lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                </div>
                <div className="col-12">
                    <label htmlFor="name" className="form-label">Entreprise</label>
                    <input type="text" className="form-control" id="name" name="name" value={entreprise} onChange={(e) => setEntreprise(e.target.value)} />
                </div>
                <div className="col-12">
                    <label htmlFor="inputAddress2" className="form-label">Ville</label>
                    <input type="text" className="form-control" id="inputAddress2" value={structure.ville_nom_reel} placeholder="Par exemple : Paris" onChange={(e) => {
                        let oldStructure = structure;
                        oldStructure.ville_nom_reel = e.target.value;
                        setStructure(oldStructure);

                        search(e.target.value)

                        return;
                    }
                    } />
                </div>
                <div className="p-2">
                    {
                        !isEmpty(cities) && cities.map((city, index) => {
                            return (
                                <div key={index} className="p-1" onClick={() => setCity(city)}>
                                    {city.ville_nom_reel} ({city.ville_code_postal})
                                </div>    
                            );
                        })
                    }
                </div>
                <div className="col-12">
                    <label htmlFor="name" className="form-label">Facturation à l'heure (0 si aucune)</label>
                    <input type="number" className="form-control" id="price" name="price" value={price} onChange={(e) => setPrice(e.target.value)} />
                </div>
                <div className="row box">
                    <h6>Objectifs (0 si aucun)</h6>
                </div>    
                <div className="row box">
                    <div className="col-lg-4 pe-2">
                        <label htmlFor="name" className="form-label">Par jour</label>
                        <div className="row">
                            <input type="number" className="form-control col-lg-6 w-25 text-right" id="dayTarget" name="dayTarget" value={dayHoursTarget} onChange={(e) => setDayHoursTarget(e.target.value)} />h 
                            <input type="number" className="form-control col-lg-6 w-25 text-right" id="dayTarget" name="dayTarget" value={dayMinutesTarget} onChange={(e) => setDayMinutesTarget(e.target.value)} />mn
                        </div>    
                    </div>
                    <div className="col-lg-4 pe-2">
                        <label htmlFor="name" className="form-label">Par semaine</label>
                        <div className="row">
                            <input type="number" className="form-control col-lg-6 w-25 text-right" id="dayTarget" name="dayTarget" value={weekHoursTarget} onChange={(e) => setWeekHoursTarget(e.target.value)} />h 
                            <input type="number" className="form-control col-lg-6 w-25 text-right" id="dayTarget" name="dayTarget" value={weekMinutesTarget} onChange={(e) => setWeekMinutesTarget(e.target.value)} />mn
                        </div>
                    </div>
                    <div className="col-lg-4 pe-2">
                        <label htmlFor="name" className="form-label">Par mois</label>
                        <div className="row">
                            <input type="number" className="form-control col-lg-6 w-25 text-right" id="dayTarget" name="dayTarget" value={monthHoursTarget} onChange={(e) => setMonthHoursTarget(e.target.value)} />h 
                            <input type="number" className="form-control col-lg-6 w-25 text-right" id="dayTarget" name="dayTarget" value={monthMinutesTarget} onChange={(e) => setMonthMinutesTarget(e.target.value)} />mn
                        </div>
                    </div>
                </div>    
                <div className="col-12 text-right">
                    <button className="btn btn-secondary" onClick={() => history.push('/structures')}>Annuler</button>{' '}
                    <button className="btn btn-primary" onClick={handleSubmit}>Sauvegarder</button>
                </div>
                </form>
        )
    }
}

export default StructureForm;