import config from '../../config.json';

export const getWeeks = async (structureUid, month, year) => {
    try {
        const token = localStorage.getItem('_k');

        return fetch(config[0].apiUrl + "/works.php?q=statsweeks&structure=" + structureUid + "&month=" + month + "&year=" + year + "&token=" + token)
        .then(res => res.json())
        .then(
            (result) => {
                return result;
            }
        )
    } catch (error) {
        console.log(error);
    }
    
  }

  export const getOvertime = (structureUid) => {
    const token = localStorage.getItem('_k');

    return fetch(config[0].apiUrl + "/structures.php?q=overtime&uid=" + structureUid + "&token=" + token)
      .then(res => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          console.log(error);

          return;
        }
      )
  }

  export const convertMinutesToHours = (totalMinutes) => {
    var hours = Math.floor(totalMinutes / 60);
    var minutes = Math.round(totalMinutes % 60);

    return {"hours": hours, "minutes": minutes}
  }

  export const getMonthName = (month) => {
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    return monthNames[month-1];
  }