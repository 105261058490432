import config from '../../config.json';
import { checkAuth } from '../helpers/authHelper';

export const fetchRequest = (url, method, params = {}) => {

    if (url !== '/auth.php' && method !== 'POST') checkAuth();

    var options = {
        method: method,
        Accept: "*/*",
    };

    const token = localStorage.getItem('_k');

    if ( 'GET' === method ) {
        url += '?' + ( new URLSearchParams( params ) ).toString() + "&token=" + token;
    } else {
        const formData  = new FormData();

        for(const name in params) {
            formData.append(name, params[name]);
        }

        options.body = formData;
        url += "?token=" + token
    }

    return new Promise(async function(resolve, reject) {
        try {
            const res = await fetch(config[0].apiUrl + url, options);
            const result_1 = await res.json();
            resolve(result_1);
          } catch (error) {
            console.error(error);
    
            reject(error);
          }
    })
}

export const fetchApi = async (url, method, params) => {
    return fetchRequest(url, method, params).then(function(result) {
        return result
    }).catch(function(error) {
        console.error(error);
    })
}