import React, { useState } from "react";
import { useHistory } from "react-router";
import { register } from "../helpers/authHelper";

export default function Register() {

    const history = useHistory();

    const initialState = {
        email: '',
        password: '',
        confirm: '',
        username: ''
    }

    const [{email, password, confirm, username}, setState] = useState(initialState);
    const [errorMessage, setErrorMessage] = useState("");

    const onChange = e => {
        const { name, value } = e.target;
        setState(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (email.length && password.length && confirm.length && username.length) {
                if (password === confirm) {

                    const auth = await register(username, email, password);
                    
                    localStorage.setItem('_k', auth.token);
                    return document.location = '/dashboard';
                }

                setErrorMessage("Les mots de passe ne correspondent pas.");

                return;
            }

            setErrorMessage("Merci de remplir tous les champs.");

            return;
        } catch (error) {
            setErrorMessage("Une erreur est survenue.");

            console.log(error);
        }
    };

    return (
        <div className="col-lg-4 box m-auto mt-5">
            <div className="text-center">
                <h1>Créer un compte</h1>
            </div>
            {errorMessage.length > 0 && <div className="alert alert-danger">
                { errorMessage }
            </div>}
            <div className="mt-4">
                <form className="form text-left" onSubmit={handleSubmit}>
                    <div className="form-group mt-3">
                        <label className="form-label">Nom d'utilisateur</label>
                        <input type="text" name="username" className="form-control" placeholder="John Doe" onChange={onChange} />
                    </div>
                    <div className="form-group mt-3">
                        <label className="form-label">Adresse mail</label>
                        <input type="email" name="email" className="form-control" placeholder="john.doe@example.com" onChange={onChange} />
                    </div>
                    <div className="form-group mt-3">                        
                        <label className="form-label">Mot de passe</label>
                        <input type="password" name="password" className="form-control" onChange={onChange} />
                    </div>
                    <div className="form-group mt-3">                        
                        <label className="form-label">Confirmez le mot de passe</label>
                        <input type="password" name="confirm" className="form-control" onChange={onChange} />
                    </div>
                    <div className="form-group mt-3 text-right">
                        <button className="btn btn-secondary" onClick={() => history.push('/')}>Retourner à l'accueil</button>{" "}
                        <button className="btn btn-success">Créer mon compte</button>
                    </div>    
                </form>
            </div>
        </div>
    )
}