import React from 'react';
import StructureForm from './forms/StructureForm';

function CreateStructure() {
    return (
        <React.Fragment>
            <div className="row box m-auto mt-1 text-left">
                <h1 className="h4 mb-0 col-lg-12 box-body">Créer un client</h1>
            </div>
            <div className="row">
                <StructureForm />    
            </div>
        </React.Fragment>    
    );
}

export default CreateStructure;