import React, { useState } from 'react';
import 'react-notifications-component/dist/theme.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Login from './components/views/Login.js';
import isNull from 'lodash/isNull';
import MyStructures from './components/views/MyStructures.js';
import MyDefaultDays from './components/views/MyDefaultDays.js';
import Account from './components/views/Account.js';
import Register from './components/views/Register.js';
import Settings from './components/views/Settings.js';
import StatsDetails from './components/views/StatsDetails.js';
import AddWorkModal from './components/views/modals/AddWorkModal.js';
import ReactNotification from 'react-notifications-component';
import { store } from 'react-notifications-component';
import LeftSideBar from './components/views/LeftSideBar.js';
import Hours from './components/views/Hours.js';
import NotFound from './components/views/NotFound.js';
import HomeDashboard from './components/views/HomeDashboard.js';
import Money from './components/views/Money.js';
import EditStructure from './components/views/EditStructure.js';
import Log from './components/views/Log.js';
import EditWork from './components/views/EditWork.js';
import Calendar from './components/views/Calendar.js';
import CreateStructure from './components/views/CreateStructure.js';
import Work from './components/views/Work.js';
import { clearSession } from './components/helpers/authHelper.js';
import Help from './components/views/Help.js';

export default function App() {

  const [showAddWorkModal, setShowAddWorkModal] = useState(false);

  if (isNull(localStorage.getItem('_k'))) {
    return (
      <Router>
        <div className="App">
          <ReactNotification />
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/u/register">
              <Register />
            </Route>
          </Switch>
        </div>  
      </Router>
    );
  }

  return (
    <Router>
      <div className="App">
        <ReactNotification />
        {showAddWorkModal && <AddWorkModal toggleModal={(modal) => {setShowAddWorkModal(modal)}} />}
        <div className="row">
          <div className="col-lg-2 col-md-3" id="leftSideBar">
            <LeftSideBar clearSession={clearSession} toggleModal={(modal) => {setShowAddWorkModal(modal)}} />
          </div>
          <div className="col-lg-10 col-md-9">
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <Route exact path="/structures">
                <MyStructures />
              </Route>
              <Route exact path="/account">
                <Account />
              </Route>
              <Route exact path="/default-days">
                <MyDefaultDays />
              </Route>
              <Route exact path="/hours">
                <Hours />
              </Route>
              <Route exact path="/calendar">
                <Calendar toggleModal={(modal) => {setShowAddWorkModal(modal)}} />
              </Route>
              <Route exact path="/money">
                <Money />
              </Route>
              <Route exact path="/log">
                <Log />
              </Route>
              <Route exact path="/settings">
                <Settings />
              </Route>
              <Route exact path="/u/register">
                <Register />
              </Route>
              <Route exact path="/dashboard">
                <HomeDashboard />
              </Route>
              <Route exact path="/structure/:uid/stats">
                <StatsDetails
                  clearSession={clearSession}
                />
              </Route>
              <Route exact path="/structure/:uid/edit">
                <EditStructure />
              </Route>
              <Route exact path="/structure/create">
                <CreateStructure />
              </Route>
              <Route exact path="/work/:id">
                <Work />
              </Route>
              <Route exact path="/work/:id/edit">
                <EditWork />
              </Route>
              <Route exact path="/help">
                <Help />
              </Route>
              <NotFound />
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}

export function showAddWorkModal() {
  return <AddWorkModal />
}

export const launchNotification = (type, title, message) => {
  return store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}
