import React, { useEffect, useState } from "react";
import { getLogs } from "../api/logs";
import isEmpty from 'lodash/isEmpty';
import Moment from "react-moment";
import { deleteWork } from "../api/works";
import { useHistory } from "react-router";
import { isNull } from "lodash-es";
import { Spinner } from "react-bootstrap";
import Header from "./Header";
import { getAllStructures } from "../api/structures";

export default function Log() {

    const [isLoading, setIsLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const [structures, setStructures] = useState([]);
    const [column, setColumn] = useState('created_at');
    const [orderDesc, setOrderDesc] = useState(true);
    const [order, setOrder] = useState('desc');

    const history = useHistory();

    useEffect(function(){
        getLogsApi(column, order);
    }, [])

    async function getLogsApi(column, order) {
        setIsLoading(true);
        const lgs = await getLogs(column, order);
        const str = await getAllStructures();

        setLogs(lgs);
        setStructures(str);
        setIsLoading(false);
    }

    async function handleDelete(e) {
        await deleteWork(e.target.value);    
        getLogsApi(column, order);
    
        return;
    }

    async function updateFilter(column) {
        setOrderDesc(order => !order);

        if (order === 'desc') {
            setOrder('asc');
        } else {
            setOrder('desc');
        }
                
        setColumn(column);
        getLogsApi(column, order);
    }

    return (
        <React.Fragment>
            <Header
                title="Historique"
                description="Consultez ici toutes les actions que vous avez effectuées dans votre espace personnel"
            />
            <div className="row box m-auto text-left">
                <div className="box-body">
                    { logsRender() }
                </div>    
            </div>
        </React.Fragment>
    )

    function logsRender() {
        if (isLoading) {
            return (
                <div className="col-lg-12 text-center">
                    <Spinner animation="border" variant="success" />
                </div>
            );
        }

        if (isEmpty(logs)) {
            return (
                <h2 className="h4 text-muted text-center">Aucun historique récent</h2>
            );
        }

        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        {
                            [
                                { column: "Date création", filter: "created_at" },
                                { column: "Client", filter: "structure_id" },
                                { column: "Valeur", filter: "start" },
                                { column: "Recette", filter: "price" },
                                { column: "Modifié", filter: "edited_at" },
                                { column: "Supprimé", filter: "deleted_at" },
                                { column: "Actions", filter: "actions" }].map((col, index) => {
                                return (
                                    <th key={index} className={`${column === col.filter && "text-primary"}`} onClick={() => updateFilter(col.filter)}>{column === col.filter && <i className={`bi bi-arrow-${orderDesc ? "down" : "up"}-circle-fill text-primary`}></i>} {col.column}</th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    { tableRender() }
                </tbody>
            </table>
        );
    }

    function tableRender() {
        return logs.map((log, index) => {

            return (
                <tr key={index} className={(!isNull(log.deleted_at) && log.deleted == 1) && "table-danger"}>
                    <td>
                        <Moment locale="fr" format='DD MMM YYYY'>{log.created_at}</Moment><br />
                        <Moment locale="fr" format='HH:mm'>{log.created_at}</Moment>
                    </td>
                    <td>{log.firstname} {log.lastname}{parseInt(log.overtime) === 1 && <React.Fragment><br /><span className="badge rounded-pill" style={{ backgroundColor: "#0984e3" }}>Heure supp.</span></React.Fragment>}</td>
                    <td>Du <Moment locale="fr" format='DD MMM YYYY à HH:mm'>{log.start}</Moment><br />au <Moment locale="fr" format='DD MMM YYYY à HH:mm'>{log.end}</Moment></td>
                    <td>{log.price} €</td>
                    <td>{!isNull(log.edited_at) && <span className="badge rounded-pill" style={{ backgroundColor: "#00b894" }}><Moment locale="fr" format='DD MMM YYYY à HH:mm'>{log.edited_at}</Moment></span>}</td>
                    <td>{(!isNull(log.deleted_at) || log.deleted === 1) && <span className="badge rounded-pill" style={{ backgroundColor: "#DD0000" }}><Moment locale="fr" format='DD MMM YYYY à HH:mm'>{log.deleted_at}</Moment></span>}</td>
                    <td>
                        <button className="btn btn-link" value={log.id} onClick={() => history.push('/work/' + log.id)}>voir</button>
                        {(isNull(log.deleted_at) && log.deleted == 0) && <button className="btn btn-link" value={log.id} onClick={handleDelete}>supprimer</button>}
                    </td>
                </tr>
            );
        })
    }
}