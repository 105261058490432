import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export default function LeftSideBar({ clearSession, toggleModal }) {
    let history = useHistory();
    let pathname = useParams();

    /*useEffect(function() {
      const container = document.getElementById("addWorkModal");
      const modal     = new window.bootstrap.Modal(container);

      document.getElementById("addWorkModalBtnShow").addEventListener("click", function () {
        modal.show();
      });

      document.getElementById("addWorkModalBtnHide").addEventListener("click", function () {
        modal.hide();
      });
    }, [])*/

    return (
        <React.Fragment>
            <div className="p-3 text-center">
                <button
                  onClick={() => toggleModal(true)}
                  id="addWorkModalBtnShow"
                  className="btn btn btn-primary col-lg-12"
                  >Ajouter une journée</button>
              </div>
              <div className="list-group text-left list-group-flush">
                <div className={`list-group-item list-group-item-action ${pathname === "/dashboard" && "active"}`} aria-current="true" onClick={() => history.push('/dashboard')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-speedometer"></i> <h6 className="mb-1">Vue d'ensemble</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/hours" && "active"}`} aria-current="true" onClick={() => history.push('/hours')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-hourglass"></i> <h6 className="mb-1">Mes heures</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/log" && "active"}`} aria-current="true" onClick={() => history.push('/log')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-clock-history"></i> <h6 className="mb-1">Historique</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/calendar" && "active"}`} aria-current="true" onClick={() => history.push('/calendar')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-calendar"></i> <h6 className="mb-1">Emploi du temps</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/money" && "active"}`} aria-current="true" onClick={() => history.push('/money')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-cash-stack"></i> <h6 className="mb-1">Mes revenus</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/structures" && "active"}`} aria-current="true" onClick={() => history.push('/structures')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-building"></i> <h6 className="mb-1">Mes structures</h6>
                  </div>
                </div>
                {/*<div className={`list-group-item list-group-item-action ${pathname === "/default-days" && "active"}`} aria-current="true" onClick={() => history.push('/default-days')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-code-square"></i> <h6 className="mb-1">Gérer mes journées types</h6>
                  </div>
              </div>*/}
                <div className={`list-group-item list-group-item-action ${pathname === "/account" && "active"}`} aria-current="true" onClick={() => history.push('/account')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-person-circle"></i> <h6 className="mb-1">Mon compte</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/settings" && "active"}`} aria-current="true" onClick={() => history.push('/settings')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-gear-wide-connected"></i> <h6 className="mb-1">Paramètres</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/help" && "active"}`} aria-current="true" onClick={() => history.push('/help')}>
                  <div className="d-flex w-100">
                  <i className="bi bi-info-circle-fill"></i> <h6 className="mb-1">Aide</h6>
                  </div>
                </div>
                <div className={`list-group-item list-group-item-action ${pathname === "/logout" && "active"}`} aria-current="true" onClick={() => clearSession()}>
                  <div className="d-flex w-100">
                  <i className="bi bi-box-arrow-right"></i> <h6 className="mb-1">Déconnexion</h6>
                  </div>
                </div>
              </div>
        </React.Fragment>
    )
}