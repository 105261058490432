import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from "react-router";

class MyDefaultDays extends Component {
  constructor(props) {
    super(props);

    this.state = {
      structures: [],
      isLoading: false,
      iconSearch: "",
      isLoadingIcons: false,
      icons: [],
      apiKey: "apik_j2h4m192"
    }

    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.saveStructure = this.saveStructure.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleIconChange = this.handleIconChange.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const structures = await this.props.getAllStructures();

      this.setState({ structures })
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleSearch(event) {
    try {
      this.setState({ iconSearch: event.target.value, isLoadingIcons: true, icons: [] })
      const options = {
        method: 'GET',
        headers: {
          Authorization: 'Bearer yoFYvAh46zsxqPCPj7wkxdMt6i2Y75yIga4mtZBquJCnR5QjhKpwujA8Mr8Gge5V'
      }
    };
    
    fetch('https://iconfinder-api-auth.herokuapp.com/v4/icons/search?query=' + event.target.value + '&count=10&premium=0&vector=1', options)
    .then(res => res.json())
    .then(
      (result) => {
        console.log(result);
        this.setState({ icons: result.icons });
        this.setState({ isLoadingIcons: false })
        return result;
      })
    } catch (error) {
      console.log();
      this.setState({ isLoadingIcons: false })
    }    
  }

  handleInputChange(event, name, structureIndex) {
    const { value } = event.target;
    let {structures} = this.state;

    structures[structureIndex][name] = value;

    return this.setState({ structures });
  }

  handleColorChange(color, structureIndex) {
    let {structures} = this.state;

    structures[structureIndex].color = color.hex;

    return this.setState({ structures });
  }

  handleIconChange(icon, structureId) {
    let {structures} = this.state;
    structures[structureId].icon = icon;

    this.setState({ structures }, function() { console.log(this.state); });
  }

  async saveStructure(e, structureIndex) {
    e.preventDefault();
    const structure = this.state.structures[structureIndex];
    
    try {
      this.setState({ isSavingStructure: true })
      await this.props.saveStructure(structure);

      return this.props.launchNotification('success', 'Succès', 'La structure a bien été modifiée');
    } catch (error) {
      console.log(error);

      return this.props.launchNotification('danger', 'Erreur', 'Une erreur est survenue lors de la modification de la journée');
    } finally {
      this.setState({ isSavingStructure: false })
    }
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row container m-auto mt-4 p-4 bg-white">
          <div className="col-lg-12 text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>  
      )
    }

    return (
      <React.Fragment>
        <div className="row container bg-white m-auto p-4 mt-4 text-left">
          <h1 className="fw-lighter mb-0">Mes journées types</h1>
          <h5 className="text-muted fw-light">Les journées types vous permettent de gagner du temps lorsque vous ajoutez des heures de travail. Vous définissez une journée type (structure, heures, temps de pause, etc.) et vous n'aurez plus qu'à cliquer sur cette dernière pour l'ajouter.</h5>
          <div className="row mt-4 mb-4">
            <div className="col-lg-12 text-right">
              <button type="button" className="btn btn-success">Créer une journée type</button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
            </div>
          </div>
        </div>
      </React.Fragment>  
    );
  }
}

export default withRouter(MyDefaultDays);