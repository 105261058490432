import { isNull } from "lodash-es";
import { fetchApi } from "./api"

export const checkAuth = async () => {
    if (isNull(localStorage.getItem('_k'))) {
        return document.location = "/login";
    }

    return fetchApi('/auth.php', 'POST', {
        action: 'checktoken'
    })
}

export const getMe = async () => {
    return fetchApi('/auth.php', 'GET', {
        action: 'me'
    })
}

export const login = async (email, password) => {
    return fetchApi('/auth.php', 'POST', {
        action: 'login',
        email: email,
        password: password
    })
}