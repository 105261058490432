export const workedTimeRender = (minutes) => {
    let total = 0;
    let time = { hours: 0, minutes: 0 };
    
    time = convertMinutesToHours(minutes);    
  
    total = time.hours + "h";
  
    if (time.minutes > 0) {
      total =  time.hours + "h" + time.minutes;
    }
  
    return total;
  }

  function convertMinutesToHours(totalMinutes) {
    var hours = Math.floor(totalMinutes / 60);
    var minutes = Math.round(totalMinutes % 60);

    return {"hours": hours, "minutes": minutes}
  }