import { isNull } from 'lodash-es';
import config from '../../config.json';

export const register = async (username, email, password) => {
    try {
        var user = new FormData();
        user.append('username', username);
        user.append('email', email);
        user.append('password', password);
        user.append('action', 'register');
    
        const response = await fetch(config[0].apiUrl + "/auth.php", {
        method: "POST",
        body: user
      });
      const data = await response.json();
      return data;
      } catch (error) {
        console.log(error);

        return;
      }
}

export const checkAuth = () => {
  if (isNull(localStorage.getItem('_k'))) {
    return document.location = "/login";
  }

  var form = new FormData();
  form.append('action', 'checktoken');
  form.append('token', localStorage.getItem('_k'));

  return fetch(config[0].apiUrl + "/auth.php", {
    method: "POST",
    body: form,
  })
  .then(handleErrors);
}

export const handleErrors = (response) => {
  if (!response.ok) {
      return clearSession();
  }
  return response;
}

export const clearSession = () => {
  localStorage.removeItem('_k');

  return document.location = "/login";
}