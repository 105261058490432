import React from 'react';
import { Spinner } from 'react-bootstrap';
import WorkForm from './forms/WorkForm';

function EditWork() {
    return (
        <React.Fragment>
            <div className="row box m-auto mt-4 text-left">
                <h1 className="h4 mb-0 col-lg-12 box-body">Modifier une entrée</h1>
            </div>
            <div className="row">
                <div className="col-lg-7 col-md-10 box">
                    <div className="box-body">
                        <WorkForm />
                    </div>    
                </div>    
            </div>
        </React.Fragment>    
    );
}

export default EditWork;