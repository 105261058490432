import { isNull } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { launchNotification } from '../../App';
import { login } from '../api/user';

export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [wrongPassword, setWrongPassword] = useState(false);
    const [isLogging, setIsLogging] = useState(false);
    const history = useHistory();

    useEffect(function() {
        if (!isNull(localStorage.getItem('_k'))) {
            history.push('/dashboard');

            return;
        }
    }, []);

    function handleLogin(e) {

        e.preventDefault();

        setIsLogging(true);

        login(email, password)
            .then((response) => {
                setWrongPassword(false);
                setIsLogging(false);
                localStorage.setItem('_k', response.token);

                document.location.href = '/dashboard';

                return;
            }).catch(e => {
                console.error(e);
                setIsLogging(false);
                launchNotification('danger', 'Erreur', 'Une erreur est survenue lors de la connexion');
            })
    }

    return (
        <div id="loginContainer" style={{ backgroundImage: `url(${'https://images.unsplash.com/photo-1493397212122-2b85dda8106b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2342&q=80'})` }} className="row">
            <div id="loginLeftSide" className="col-lg-7">
                <div>
                    <h1 className="bebas m-0 text-white">Manag'Heures</h1>
                    <h2 className="text-white m-0 h2">Enregistrez et visualisez avec précision<br />vos heures de travail.</h2>
                </div>
            </div>
            <div id="loginRightSide" className="col-lg-5">
                <div id="formContainer">
                    <div id="unicornContainer">
                        <h3 className="h1">Connexion</h3>
                    </div>
                    <form>
                        <input onChange={(e) => setEmail(e.target.value)} value={email} type="email" placeholder="Login" required />
                        <input onChange={(e) =>  setPassword(e.target.value)} value={password} type="password" placeholder="Mot de passe" required />
                        <button onClick={handleLogin} className={`btn btn-primary ${isLogging && "disabled"}`} type="button">
                            {isLogging && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
                            {isLogging ? " Connexion..." : "Se connecter"}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}