import React, { Component } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { SwatchesPicker } from 'react-color';
import Picker from 'emoji-picker-react';
import { withRouter } from "react-router";
import isEmpty from 'lodash/isEmpty';
import CreateStructureModal from './modals/CreateStructureModal.js';
import { v4 as uuidv4 } from 'uuid';
import config from '../../config.json';
import { getAllStructures } from '../api/structures.js';

class MyStructures extends Component {
  constructor(props) {
    super(props);

    this.state = {
      structures: [],
      isLoading: true,
      chosenEmoji: '',
    }

    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onEmojiClick = this.onEmojiClick.bind(this);
    this.handleCreateStructure = this.handleCreateStructure.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const structures = await getAllStructures();

      this.setState({ structures })
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false })
    }
  }

  handleCreateStructure(structure) {
    const token = localStorage.getItem('_k');
    const { name, week_target, color, icon, city } = structure;

    const uid = uuidv4();

    if (isEmpty(name) || isEmpty(week_target) || isEmpty(city) || isEmpty(color)|| isEmpty(icon)) {
      return this.props.launchNotification("danger", "Erreur", "Merci de remplir les champs obligatoires")
    }

    var structure = new FormData();
    structure.append('name', name);
    structure.append('week_target', week_target);
    structure.append('color', color);
    structure.append('icon', icon);
    structure.append('city', city);
    structure.append('uid', uid);
    structure.append('action', 'create');
    structure.append('token', localStorage.getItem('_k'));

    fetch(config[0].apiUrl + "/structures.php?token=" + token, {
      method: "POST",
      body: structure
    })
      .then(this.props.handleErrors)
      .then(
        (response) => {
          if (!response.ok) {
            return this.props.launchNotification('danger', 'Erreur', "Une erreur est survenue, la structure n'a pas pu être crée");
          }

          return this.props.launchNotification('success', 'Succès', 'La structure a bien été crée');
        }
      )
  }

  handleInputChange(event, name, structureIndex) {
    const { value } = event.target;
    let {structures} = this.state;

    structures[structureIndex][name] = value;

    return this.setState({ structures });
  }

  handleColorChange(color, structureIndex) {
    let {structures} = this.state;

    structures[structureIndex].color = color.hex;

    return this.setState({ structures });
  }

  onEmojiClick(icon, structureId) {
    let {structures} = this.state;
    structures[structureId].icon = icon.emoji;

    this.setState({ structures }, function() { console.log(this.state); });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row container m-auto mt-4 p-4 bg-white">
          <div className="col-lg-12 text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>  
      )
    }

    return (
      <React.Fragment>
        <div className="row box m-auto mt-4 text-left">
            <div className="box-body">
              <h1 className="h4 mb-0 col-lg-12">Mes clients / structures</h1>
              <h5 className="h5 text-muted text-400">Vous pouvez ici modifier vos clients et structures, en ajouter ou en créer.</h5>
              <div className="row">
                <div className="col-lg-12 text-right">
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => this.props.history.push('/structure/create')}
                  >Ajouter un client</button> 
                </div>
              </div>
            </div>
          </div>  
        <div className="row">
          <StructuresRender
            structures={this.state.structures}
            launchNotification={this.props.launchNotification}
            handleColorChange={this.handleColorChange}
            handleInputChange={this.handleInputChange}
            chosenEmoji={this.state.chosenEmoji}
            onEmojiClick={this.onEmojiClick}
            history={this.props.history}
          />
        </div>
      </React.Fragment>  
    );
  }
}

const StructuresRender = (props) => {
  return (      
    props.structures.map((structure, index) => {
      return (
        <div key={index} className="col-lg-4 col-md-6 box scale" onClick={() => props.history.push('/structure/' + structure.uid + '/edit')}>
          <div style={{ borderBottom: `3px solid ${structure.color}` }} className="box-body">
            <div className="text-left">
              <h4 className="text-secondary mb-0" style={{ width: "max-content"}}>
                {structure.firstname} {structure.lastname}
              </h4>
              <h6 className="mb-0 h6 small mt-2"><i className="bi bi-geo-alt text-muted"></i> {structure.cityName} <i className="bi bi-building text-muted"></i> {structure.name}</h6>
            </div>
          </div>
              {/*<Card.Body className="bg-white">
                <div key={index} className="row mb-2 pb-4">                
                  <div className="row">
                    <div className="col-lg-12 text-left">
                      <form>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label"><strong>Nom</strong></label>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="Nom de la structure"
                            required={true}
                            onChange={(e) => props.handleInputChange(e, "name", index)}
                            value={props.structures[index].name} />
                        </div>
                        <div className="mb-3">
                          <label htmlFor="exampleInputEmail1" className="form-label"><strong>Quota d'heures par semaine (en minutes)</strong></label>
                          <input
                            className="form-control"
                            type="number"
                            min={0}
                            name="week_target"
                            placeholder="Quotas en minutes"
                            onChange={(e) => props.handleInputChange(e, "week_target", index)}
                            required={true}
                            value={props.structures[index].week_target} />
                        </div>
                        <div className="row">
                          <div className="mb-3 col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Couleur</label>
                            <SwatchesPicker onChange={(e) => props.handleColorChange(e, index)} />
                          </div>
                          <div className="mb-3 col-lg-6">
                            <label htmlFor="exampleInputEmail1" className="form-label">Icône</label>
                            <div>
                                {
                                    props.chosenEmoji ? (
                                    <span>{props.chosenEmoji.emoji}</span>
                                ) : (
                                    <span>Aucun emoji sélectionné</span>
                                )}
                                <Picker onEmojiClick={(emoji) => props.onEmojiClick(emoji, index)} />
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 text-right">
                          <hr />
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={(e) => props.saveStructure(e, index)}>Enregistrer</button>
                        </div>
                      </form>                  
                    </div>
                  </div>       
                </div>
                                </Card.Body>*/}
      </div>
      );
    })      
  );
}

export default withRouter(MyStructures);