import { isEmpty } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { launchNotification } from "../../../App";
import { getAllStructures } from "../../api/structures";
import { getWork, saveWork } from "../../api/works";

function WorkForm({ toggleModal }) {

    const [isLoading, setIsLoading]     = useState(false);
    const [action, setAction]           = useState('create');
    const [structures, setStructures]   = useState([]);
    const [work, setWork]               = useState([{
        id: "",
        dateStart: "",
        timeStart: "",
        dateEnd: "",
        timeEnd: "",
        structure_id: 0,
        breakTime: 0,
        description: "",
        overtime: false
    }]);
    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        async function initWork() {
            const struct = await getAllStructures();
            setStructures(struct);

            if (Object.keys(params).length > 0) {
                const workId = params.id;

                setIsLoading(true);
                let work = await getWork(workId);

                if (!isEmpty(work[0])) {
                    work.dateStart          = work[0].start.substring(0, 10);
                    work.dateEnd            = work[0].end.substring(0, 10);
                    work.timeStart          = work[0].start.substring(11);
                    work.timeEnd            = work[0].end.substring(11);
                    work.structure_id       = parseInt(work[0].structure_id);
                    work.description        = work[0].description;
                    work.overtime           = parseInt(work[0].overtime);
                    work.breakTime          = work[0].breakTime;
                    work.id                 = work[0].workId;

                    setAction('update');
                    setWork(work);
    
                    setIsLoading(false);
                }
            }
        }

        initWork();

    }, [])

    const onChange = e => {
        const { name, value } = e.target;
        setWork(prevState => ({ ...prevState, [name]: value }));
    };

    function handleSubmit(e) {
        e.preventDefault();

        var workFormData = {
            id: action === 'create' ? "" : work.id,
            start: work.dateStart + ' ' + work.timeStart,
            end: work.dateEnd + ' ' + work.timeEnd,
            structure_id: work.structure_id,
            description: work.description,
            overtime: work.overtime === "on" ? 1 : 0,
            breakTime: work.breakTime,
            action: action
        }

        saveWork(workFormData).then(function() {
            launchNotification('success', 'Succès', 'Informations enregistrées avec succès.')

            return;
        }).catch(function(e) {
            launchNotification('danger', 'Erreur', 'Une erreur est survenue.')
            console.log(e);

            return;
        });
    }

    return (
        <form className="text-start">
            <div className="modal-body">
                <div className="row m-2">
                    <div className="col-lg-6 col-md-6 form-group">
                        <div className="row">
                            <h5 className="text-muted">Début</h5>
                            <div className="col-lg-7 pe-2">
                                <input
                                    onChange={onChange}
                                    type="date"
                                    name="dateStart"
                                    className="form-control"
                                    id="datestart"
                                    aria-describedby="start"
                                    value={work.dateStart} />
                            </div>
                            <div className="col-lg-5 pe-2">
                                <input
                                    onChange={onChange}
                                    name="timeStart"
                                    type="time"
                                    className="form-control"
                                    id="timestart"
                                    aria-describedby="timestart"
                                    value={work.timeStart} />
                            </div>
                        </div>  
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="row">
                            <h5 className="text-muted">Fin</h5>
                            <div className="col-lg-7 ps-2">
                                <input
                                    onChange={onChange}
                                    type="date"
                                    name="dateEnd"
                                    className="form-control"
                                    id="dateend"
                                    aria-describedby="dateend"
                                    value={work.dateEnd} />
                            </div>
                            <div className="col-lg-5 ps-2">
                                <input
                                    onChange={onChange}
                                    name="timeEnd"
                                    type="time"
                                    className="form-control"
                                    id="timeend"
                                    aria-describedby="timeend"
                                    value={work.timeEnd} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-2 mt-3">
                    <div className="col-lg-6 col-md-6 pe-2">
                    <h5 className="text-muted">Client</h5>
                        { !isEmpty(structures) &&
                            <select
                                name="structure_id"
                                className="form-select"
                                aria-label="Default select example"
                                value={work.structure_id}
                                onChange={onChange}
                            >
                                {
                                    structures.map((structure, index) => {
                                        return (
                                            <option key={index} value={structure.id}>{structure.firstname} {structure.lastname} ({structure.name})</option>
                                        );
                                    })
                                }
                            </select>
                        }
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <h5 className="text-muted">Temps de pause</h5>
                        <div className="col-lg-12 ps-2">
                            <input
                                placeholder="Temps de pause"
                                type="number"
                                name="breakTime"
                                min={0}
                                max={60}
                                className="form-control"
                                id="breakTime"
                                aria-describedby="breakTime"
                                onChange={onChange}
                                value={work.breakTime} />
                        </div>
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-lg-11">
                        <input
                            type="checkbox"
                            name="overtime"
                            className="form-check-input"
                            id="overtime"
                            onChange={onChange}
                            checked={work.overtime}
                            style={{ marginRight: '10px' }} />
                        <label className="form-check-label" htmlFor="overtime">Heures supplémentaires</label>
                    </div>    
                </div>
                <div className="row m-2">
                    <div className="col-lg-12">
                        <textarea                            
                            className="form-control"
                            id="description"
                            name="description"
                            rows="5"
                            placeholder="Notes..."
                            onChange={onChange}
                            value={work.description}></textarea>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" id="addWorkModalBtnHide" onClick={() => {
                    if (action === 'create') return toggleModal(false);

                    history.goBack();

                    return;
                }} className="btn btn-secondary">Annuler</button>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Envoyer</button>
            </div>
        </form>
    );
}

export default WorkForm;