import React from 'react';
import Header from './Header';

export default function Settings() {

    return (
      <React.Fragment>
        <Header
          title="Réglages"
          description=""
        />
        <div className="row box">
            <div className="col-lg-12 box-body">
          </div>
        </div>
      </React.Fragment>  
    );
}