import { CountUp } from "countup.js";
import React, { useEffect, useState } from "react";
import { getTotalPrice } from "../api/structures";
import ChangeMonth from "./ChangeMonth";
import Header from "./Header";

export default function Money() {

  const [prices, setPrices] = useState([]);
  const [total, setTotal] = useState(0);

  

  useEffect(function() {
    let tmpTotal = 0;
    prices.forEach(price => {
      tmpTotal += parseInt(price.total);
    })

    setTotal(tmpTotal);

    const options = {
        duration: 1.5,
    };

    let demo = new CountUp(document.getElementById('total'), tmpTotal, options);
    if (!demo.error) {
      demo.start();
    } else {
      console.error(demo.error);
    }
  }, [prices])

  return (
    <React.Fragment>
        <Header
          title="Mes revenus"
          description=""
        />
        <ChangeMonth callback={async (month, year) => {
          getTotalPrice(month, year).then(function(result) {
            setPrices(result)
          })
        }} />
        <div className="row">
            <div className="col-lg-9 box">
              <div className="box-body"></div>
                <div className="row">
                  <div className="box-body">
                    {
                      prices.map((price, index) => {
                        return (
                          <div className="row mb-4">
                            <div className="col-lg-10 text-left">
                              <h4 className="h6">{price.firstname} {price.lastname} <span className="fw-light">({price.name})</span></h4>
                              <div className="progress2">
                                <div className="progress-value2" style={{ width: price.total + "%", background: price.color }}>
                                </div>
                              </div>
                            </div> 
                            <div className="col-lg-2">
                              <h4 style={{ color: price.color }}>{price.total}€</h4>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>  
                </div>  
            </div>
            <div className="col-lg-3 box">
              <div className="box-body">
                <h2 className="h3">Total</h2>
                <h2 className="h2 display-5"><span id="total"></span> €</h2>
              </div>  
            </div>    
        </div>
    </React.Fragment>
  );
}