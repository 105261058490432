import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { getHelps } from '../api/help';
import Header from './Header';

export default function Help() {

    const [helps, setHelps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(function() {
        async function getH() {
            setIsLoading(true);
            const h = await getHelps();
            setHelps(h);
            setIsLoading(false);
        }

        getH();        
    }, []);

    return (
      <React.Fragment>
        <Header
          title="Aide"
          description="Vous avez une question ? Nous avons peut-être la réponse !"
        />
        <div className="row">
            { helpsRender() }
        </div>
      </React.Fragment>  
    );

    function helpsRender() {
        if (isLoading) {
            return (
                <div className="col-lg-12 text-center">
                    <Spinner animation="border" variant="primary" />
                </div>
            );
        }

        return (<div className="row">
            {helps.length > 0 && helps.map((hlp, index) => {
                return (
                    <div key={index} className="box col-lg-4 text-left scale">
                        <div className="box-body">
                            <h5 className="h5">{hlp.title}</h5>
                            <p>{hlp.content}</p>
                        </div>    
                    </div>
                );
            })}
        </div>)
    }
}