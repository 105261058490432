import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { launchNotification } from '../../App';
import { getMe } from '../api/user';
import Header from './Header';

export default function Account() {
  const [me, setMe] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newPasswordBis, setNewPasswordBis] = useState("");

  useEffect(function() {
    async function initMe() {
      try {
        setIsLoading(true);
        const me = await getMe();
        console.log(me);
  
        setMe(me)
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    initMe();
  }, [])

  function handleInputChange(event) {
    const { value, name } = event.target;
  }

  async function saveUser(e) {
    e.preventDefault();
    
    try {
      setIsSaving(true);
      await saveUser(me);

      return launchNotification('success', 'Succès', 'Enregistré !');
    } catch (error) {
      console.log(error);

      return launchNotification('danger', 'Erreur', 'Une erreur est survenue lors de l\'enregistrement');
    } finally {
      setIsSaving(false)
    }
  }

  if (isLoading) {
    return (
      <div className="row container m-auto mt-4 p-4 bg-white">
        <div className="col-lg-12 text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      </div>  
    )
  }

  return (
    <React.Fragment>
      <Header
        title="Mon compte"
        description="Sont listées ici vos informations personnelles. Vous avez la possibilité de changer votre mot de passe ou votre avatar."
      />
      <div className="row box">
        <div className="col-lg-6 box-body">
          <table className="table text-right">
            <tbody>
              <tr>
                <td>Pseudo</td>
                <td>
                  <input
                    className="form-control"
                    type="text"
                    value={me.username}
                    onChange={handleInputChange}
                    name="username"
                  />
                </td>
              </tr>
              <tr>
                <td>Adresse mail</td>
                <td>
                  <input
                    className="form-control"
                    type="email"
                    value={me.email}
                    onChange={handleInputChange}
                    name="email"
                  />
                </td>
              </tr>
              <tr>
                <td>Nouveau mot de passe</td>
                <td>
                  <input
                    className="form-control"
                    type="password"
                    value={newPasswordBis}
                    placeholder="Votre nouveau mot de passe"
                    onChange={handleInputChange}
                    name="newPassword"
                  />
                </td>
              </tr>
              <tr>
                <td>Répéter nouveau mot de passe</td>
                <td>
                  <input
                    className="form-control"
                    type="password"
                    value={newPasswordBis}
                    placeholder="Répétez votre mot de passe"
                    onChange={handleInputChange}
                    name="newPassword"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row box">
        <div className="col-lg-6 text-right box-body">
          <button type="button" className="btn btn-success">Sauvegarder</button>
        </div>
      </div>
    </React.Fragment>  
  );
}