import React, { useState, useEffect } from 'react';
import '../../App.css';
import { workedTimeRender } from '../utils/WorkTimeRender';
import stopwatch from '../../assets/stopwatch.png';
import shoppingbasket from '../../assets/shopping-basket.png';
import group from '../../assets/group.png';
import { useHistory } from 'react-router';
import { getAllStructures, getTotalPrice } from '../api/structures';
import { getMonthName } from '../helpers/dateTimeHelper';
import { Spinner } from 'react-bootstrap';
import { getHoursTotal } from '../api/works';

function HomeDashboard() {
    const [totalHours, setTotalHours] = useState(0);
    const [turnover, setTurnover] = useState(0);
    const [structures, setStructures] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);

    let history = useHistory();

    useEffect(function() {
        var d       = new Date();
        var month   = d.getMonth();
        const year  = d.getFullYear();

        month++;

        setMonth(month);
        setYear(year);
    }, [])

    useEffect(() => {
        setIsLoading(true);
        fetchData();
        setIsLoading(false);
    }, [])

    useEffect(function () {
        fetchData(month, year);
    }, [month, year])

    async function goToOtherMonth(context) {

        if (context === "next") {
            if (month === 12) {
                setMonth(1);
                setYear(year + 1);
            } else {
                setMonth(month + 1);
            }

            setIsLoading(true);
            await fetchData();
            setIsLoading(false);
            
            return;
        }

        if (month === 1) {
            setMonth(12);
            setYear(year - 1);
        } else {
            setMonth(month - 1);
        }
        
        return;
    }

    async function fetchData() {
        const hours     = await getHoursTotal(month, year);
        const prices    = await getTotalPrice(month, year);

        let totalhours = 0;

        hours.forEach(hour => {
            totalhours += parseInt(hour.minutesWorked);
        });

        let totalprice = 0;

        prices.forEach(price => {
            totalprice += parseFloat(price.total);
        });

        const allStructures = await getAllStructures();
        
        
        setTurnover(totalprice);
        setTotalHours(totalhours);
        setStructures(allStructures);
    }

    if (isLoading) {
        return (
            <div className="row">
              <div className="col-lg-12 text-center p-5">
                <Spinner animation="border" variant="success" />
              </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12 box text-left">
                    <h1 className="text-muted">Bonjour !</h1>
                </div>
            </div>
            <div className="row mt-2 box">
                <div className="box-body text-left row">
                    <div className="col-lg-1 text-left">
                        <button
                        className="btn btn-light"
                        onClick={() => goToOtherMonth("previous")}><i className="bi bi-caret-left-fill text-muted"></i></button>{" "}
                        <button
                        className="btn btn-light"
                        onClick={() => goToOtherMonth("next")}
                        ><i className="bi bi-caret-right-fill text-muted"></i></button>
                    </div>
                    <div className="col-lg-2 text-left btn btn-lg text-bold">
                        {getMonthName(month)} {year}
                    </div>
                </div>    
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6 box scale" onClick={() => history.push('/structures')}>
                    <div className="box-body d-flex justify-content-between">
                        <div className="w-75 text-center">
                            <div className="w-50">
                                <h2 className="h1">{structures.length}</h2>
                                <h3 className="h4 text-muted">clients</h3>
                            </div>    
                        </div>
                        <div className="w-25 text-center">
                            <img src={group} alt="stopwatch" className="card-img-top w-75" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 box scale" onClick={() => history.push('/hours')}>
                    <div className="box-body d-flex justify-content-between">
                        <div className="w-75 text-center">
                            <div className="w-50">
                                <h2 className="h1">{workedTimeRender(totalHours)}</h2>
                                <h3 className="h4 text-muted">Travaillées</h3>
                            </div>    
                        </div>
                        <div className="w-25 text-center">
                            <img src={stopwatch} alt="stopwatch" className="card-img-top w-75" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 box scale" onClick={() => history.push('/money')}>
                    <div className="box-body d-flex justify-content-between">
                        <div className="w-75 text-center">
                            <div className="w-50">
                                <h2 className="h1">{turnover} €</h2>
                                <h3 className="h4 text-muted">Générés</h3>
                            </div>    
                        </div>
                        <div className="w-25 text-center">
                            <img src={shoppingbasket} alt="stopwatch" className="card-img-top w-75" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
        
    );
}

export default HomeDashboard;