import React from "react";
import { useHistory } from "react-router";
import notfound from '../../assets/notfound.png';

export default function NotFound() {

    const history = useHistory();

    return (
        <div className="container box m-auto text-center mt-5">
            <img src={notfound} alt="404 Page non trouvée" className="col-lg-6" />
            <h2>Page non trouvée</h2>
            <hr />
            <button className="btn btn-primary" onClick={() => history.push('/')}>Retourner à l'accueil</button>
        </div>
    )
}