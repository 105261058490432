import React, { Component } from 'react';
import '../../App.css';
import Spinner from 'react-bootstrap/Spinner';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from "react-router";
import { checkAuth } from '../helpers/authHelper';
import { convertMinutesToHours, getMonthName } from '../helpers/dateTimeHelper';
import { getAllStructuresStats } from '../api/structures';
import { ResponsiveContainer, PieChart, Pie, Legend, Label, LabelList, Cell, Tooltip } from 'recharts';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Header from './Header';

class Hours extends Component {
    constructor(props) {
        super(props);

        var d       = new Date();
        var month   = d.getMonth();
        const year  = d.getFullYear();

        month++;

        this.state = {
          structures: [],
          structuresStats: [],
          isLoading: true,
          isLoadingAmounts: true,
          isLoadingStats: false,
          isLoadingTotal: true,
          dateStart: '',
          timeStart: '',
          dateEnd: '',
          timeEnd: '',
          overtime: false,
          structure: 0,
          breakTime: 0,
          description: '',
          selectedCity: 1,
          monthAmount: 0,
          weekAmount: 0,
          total: [],
          weeks: [],
          month: month,
          year: year,
          pieStats: []
        }

        this.loadingStats         = this.loadingStats.bind(this);
        this.goToOtherMonth       = this.goToOtherMonth.bind(this);
        this.getHoursStats        = this.getHoursStats.bind(this);
      }

      loadingStats(bool) {
        this.setState({
            isLoadingStats: bool
        })
      }
    
      componentDidMount() {        
        checkAuth();

        this.getHoursStats(); 
      }

      async getHoursStats() {

        const { month, year } = this.state;
        let data = [];

        try {
          this.loadingStats(true);

          let structures = await getAllStructuresStats('month', month, year);

          structures.forEach(structure => {
            data.push({ name: structure.firstname, value: structure.stats.month_work, color: structure.color })
          });

          this.setState({ structures, pieStats: data});

          this.loadingStats(false);

          return;

        } catch (error) {
          console.log(error);
        }
      }

      goToOtherMonth(context) {
        if (context === "next") {
          this.setState((prevState) => {
            return {
              year: prevState.month === 12 ? prevState.year + 1 : prevState.year,
              month: prevState.month === 12 ? 1 : prevState.month + 1
            }
            
          }, function() {
            this.getHoursStats();

            return;
          })

          return;          
        }

        this.setState((prevState) => {
          return {
            year: prevState.month === 1 ? prevState.year - 1 : prevState.year,
            month: prevState.month === 1 ? 12 : prevState.month - 1
          }
          
        }, function() {
          this.getHoursStats();

          return;
        })

        return;
      }

    render() {
        return (
        <React.Fragment>
          <Header
            title="Mes heures de travail"
            description="Cliquez sur les cartes pour accéder aux statistiques détaillées de vos heures."
          />  
          <div className="row">
            <div className="col-lg-9 box">
              <div className="row box-body">
                  <div className="col-lg-2 text-left">
                      <button
                        className="btn btn-light"
                        onClick={() => this.goToOtherMonth("previous")}><i className="bi bi-caret-left-fill text-muted"></i></button>{" "}
                      <button
                        className="btn btn-light"
                        onClick={() => this.goToOtherMonth("next")}
                        ><i className="bi bi-caret-right-fill text-muted"></i></button>
                  </div>
                  <div className="col-lg-2 text-left btn btn-lg text-bold">
                      {getMonthName(this.state.month)} {this.state.year}
                  </div>  
              </div>    
              <div className="row mb-5">
                <StructuresRender
                    structures={this.state.structures}
                    history={this.props.history}
                    month={this.state.month}
                    year={this.state.year}
                    isLoadingStats={this.state.isLoadingStats}
                    loadingStats={this.loadingStats}
                />
              </div>


                      {/*<React.Fragment>
              <div className="App">
                <div className="row col-lg-11 mx-auto mt-4">
                  <div className="col-lg-12" id="leftDashboard">
                    <div className="row">
                      <div className="col-lg-12">
                        <ul className="nav nav-pills mb-3 bg-white" id="pills-tab" role="tablist">
                          {
                            this.state.cities.map((city, index) => {
                              return (
                                <li key={index} className="nav-item" role="presentation">
                                  <a
                                      className={`nav-link`}
                                      data-bs-toggle="pill"
                                      href={`#${city.code_name}`}
                                      role="tab"
                                      aria-controls={`${city.code_name}`}
                                      aria-selected="true"
                                      onClick={() => this.setState({ selectedCity: city.id }, function() { return this.componentDidMount() })}>{city.name}</a>
                                </li>
                              );
                            })
                          }
                          <li className="nav-item" role="presentation">
                            <a
                              className="nav-link"
                              data-bs-toggle="pill"
                              href="#total"
                              role="tab"
                              aria-controls="total"
                              aria-selected="false"
                              >Total année</a>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                        {
                          this.state.cities.map((city, index) => {
                            return (
                              <div key={index} className={`tab-pane fade show card-body`} id={city.code_name} role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row mb-4">
                                  <div className="card m-1 col-lg-3 radius-10">
                                    <div className="card-body text-left">
                                      <h5 className="card-title text-muted">Ville</h5>
                                      <h2 className="card-text">{city.name}</h2>
                                    </div>
                                  </div>
                                  <div className="card m-1 col-lg-3 radius-10">
                                    <div className="card-body">
                                      <h5 className="card-title text-muted">Mois</h5>
                                      <h2 className="card-text">{this.state.isLoadingAmounts ? <Spinner animation="border" variant="success" /> : <AmountTimeRender amount={this.state.monthAmount} convertMinutesToHours={this.convertMinutesToHours} />}</h2>
                                    </div>
                                  </div>
                                  <div className="card m-1 col-lg-3 radius-10">  
                                    <div className="card-body">
                                      <h5 className="card-title text-muted">Semaine</h5>
                                      <h2 className="card-text">{this.state.isLoadingAmounts ? <Spinner animation="border" variant="success" /> : <AmountTimeRender amount={this.state.weekAmount} convertMinutesToHours={this.convertMinutesToHours} />}</h2>
                                    </div>
                                  </div>
                                  <div className="card m-1 col-lg-2 radius-10">      
                                    <div className="card-body">
                                      <h5 className="card-title text-muted">Écart type</h5>
                                      <h2 className="card-text">-</h2>
                                    </div>
                                  </div>
                                </div>
                                {!isEmpty(this.state.structures) &&
                                  <Structures
                                    getStructuresByCity={this.getStructuresByCity}
                                    city={city.id}
                                    convertMinutesToHours={this.convertMinutesToHours}
                                    clearSession={this.props.clearSession}
                                    structures={this.state.structures}
                                    structuresStats={this.state.structuresStats}
                                    launchNotification={this.props.launchNotification}
                                    getStats={this.getStats}
                                  />}
                              </div>
                            );
                          })
                        }
                          <div className="tab-pane fade" id="total" role="tabpanel" aria-labelledby="total">
                            <div className="row bg-white">
                              <div className="col-lg-12">
                                <Chart
                                  months={this.state.total}
                                  isLoadingTotal={this.state.isLoadingTotal}
                                  convertMinutesToHours={this.convertMinutesToHours}
                                  structures={this.state.structures}
                                  getOvertime={this.props.getOvertime}
                                  getAllStructures={this.props.getAllStructures}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
                    </React.Fragment>*/}
            </div>
            <div className="col-lg-3 box text-center">
              <div className="box-body">
                <h5 className="h5">Répartition des heures</h5>
                <div style={{ width: '100%', height: 400 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie dataKey="value" nameKey="name" data={this.state.pieStats} label>
                        {
                          this.state.pieStats.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color}/>
                          ))
                        }
                      </Pie>
                      <Tooltip />
                      <Legend verticalAlign="bottom" height={36}/>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>  
          </div>  
        </React.Fragment>
    );
  }
}

const StructuresRender = (props) => {

    let { structures, history, isLoadingStats, month, year } = props;

    if (isLoadingStats) {
        return (
        <div className="row">
          <div className="col-lg-12 text-center p-5">
            <Spinner animation="border" variant="success" />
          </div>
        </div>)
    }

    if (isEmpty(structures)) {
        return (<h2 className="text-muted">Aucun client</h2>)
    }    

    return structures.map((structure, index) => {
      const { month_work, month_target, overtime } = structure.stats;
        const minutesWorked = convertMinutesToHours(month_work);
        const overtimeMinutes = convertMinutesToHours(overtime);
        const minutesTarget = month_target > 0 ? convertMinutesToHours(month_target) : 0;
        let overtimeMin = month_target - month_work;
  
        if (!overtimeMin) {
          overtimeMin = 0;
        }
  
        const percentageOfWork = Math.floor(( month_work / month_target ) * 100 | 0);

        return (
          <div key={index} className="col-lg-4 col-md-6 box scale" onClick={() => history.push({
            pathname: '/structure/' + structure.uid + '/stats',
            state: { month: month, year: year }
            })}>
            <div style={{ borderBottom: `3px solid ${structure.color}` }} className="box-body">
              <div className="text-left">
                <h4 className="text-secondary me-2 m-0" style={{ width: "max-content"}}>
                  {structure.firstname} {structure.lastname}
                </h4>
                <h6 className="mb-0 h6 small fw-light">{structure.name} ({structure.cityName})</h6>
                <div className="d-flex justify-content-between align-items-end mt-3">
                  <h4 className="inline align-baseline m-0">{`${minutesWorked.hours}h${minutesWorked.minutes > 0 ? minutesWorked.minutes : ''}`}{minutesTarget !== 0 ? <span className="text-muted text-400">/{minutesTarget.hours}h{minutesTarget.minutes}</span> : ""}</h4>
                  {overtime > 0 &&
                    <h5 className="inline align-baseline m-0"><span className="text-muted text-400">supp :</span> {`${overtimeMinutes.hours}h${overtimeMinutes.minutes > 0 ? overtimeMinutes.minutes : ''}`}</h5>
                  }
                </div>
                {month_target > 0 && <div className="row text-left mt-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <div className="col-lg-10">
                    <div className="progress1">
                      <div className="progress-value1" style={{ width: percentageOfWork + "%", background: structure.color }}></div>
                    </div>
                  </div> 
                  <div className="col-lg-2">
                    <h4 style={{ color: structure.color }}>{percentageOfWork}%</h4>
                  </div> 
                </div>}
              </div>
            </div>
        </div>
        );
      })
  }

export default withRouter(Hours);