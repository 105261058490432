import React from 'react';
import { useParams } from 'react-router';
import StructureForm from './forms/StructureForm';

function EditStructure() {
    const params = useParams();

    return (
        <React.Fragment>
            <div className="row box m-auto mt-4 text-left">
                <h1 className="h4 mb-0 col-lg-12 box-body">Modifier un client</h1>
            </div>
            <div className="row">
                <StructureForm structureUid={params.uid} />
            </div>
        </React.Fragment>    
    );
}

export default EditStructure;