import { isEmpty } from 'lodash-es';
import { launchNotification } from '../../App';
import config from '../../config.json';
import { createWork } from '../api/works';

export const getHistoric = async (structure, month, year) => {
    try {
      return await fetch(config[0].apiUrl + "/works.php?q=historic&structure=" + structure + "&month=" + month + "&year=" + year + "&token=" + localStorage.getItem('_k'))
        .then(res => res.json())
        .then(
          (historic) => {
            return historic;
          }
        )
    } catch (error) {
        console.log(error);
        
        return;
    }
  }

  export const handleCreateWork = (work) => {
    const { start, end, structure } = work;

    if (isEmpty(start) || isEmpty(end)|| isEmpty(structure)) {
      launchNotification("danger", "Erreur", "Merci de remplir les champs obligatoires");

      return;
    }

    createWork(work).then(() => {
      document.getElementById("addWorkModalBtnHide").click();
      launchNotification('success', 'Succès', 'La journée a bien été créée !');

      return;
    });

    return;
  }