import { isEmpty, isUndefined } from 'lodash-es';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from "react-router";
import { workedTimeRender } from '../utils/WorkTimeRender';
import Moment from 'react-moment';
import 'moment/locale/fr';
import { getWeeks } from '../helpers/dateTimeHelper';
import { getHistoric } from '../helpers/workHelper';
import { getStructureStats } from '../helpers/structureHelper';
import Button from 'react-bootstrap/Button';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { deleteWork } from '../api/works';
import { isNull } from 'lodash-es';

class Settings extends Component {
  constructor(props) {
    super(props);

    var d       = new Date();
    var month   = d.getMonth();
    const year  = d.getFullYear();

    month++;

    this.state = {
      isLoading: false,
      structure: this.props.match.params.uid,
      structureStats:  [],
      weeks: [],
      month: month,
      year: year,
      historic: [],
      datas: []
    }

    this.goToOtherMonth = this.goToOtherMonth.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      
      var { month, year, structure } = this.state;

      if (month <= 9) {
        month = `0${month}`;
      }

      if (!isUndefined(this.props.location.state)) {
        month = this.props.location.state.month;
        year  = this.props.location.state.year;

        this.setState({ month, year });
      }

      const structureStats  = await getStructureStats(structure, month, year);
      const historic        = await getHistoric(structure, month, year);
      const weeks           = await getWeeks(structure, month, year);

      let datas = [];

      weeks.forEach((week, index) => {
        const label = `Sem. ${index + 1}`;

        datas.push({
          name: label,
          time: week.minutes
        });
      })     

      this.setState({ weeks, historic, structureStats, datas })
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false })
    }
  }

  async handleDelete(e) {
    await deleteWork(e.target.value);

    var { month, structure, year } = this.state;

    if (month <= 9) {
      month = `0${month}`;
    }

    const historic = await getHistoric(structure, month, year);

    this.setState({ historic });

    return;
  }

  goToOtherMonth(context) {

    const that = this;
    const { structure } = this.state;

    // ensure that we're not in January
    var month = (context === "previous") ? this.state.month - 1 : this.state.month + 1;
    var year = this.state.year;

    if (month === 0) { month = 12; year--; }
    if (month === 13) { month = 1; year++; }

    this.setState({
      month,
      year
    }, async function() {
      if (month <= 9) { month = `0${month}`; }

      const historic        = await getHistoric(structure, month, year);
      const structureStats  = await getStructureStats(structure, month, year);
      const weeks           = await getWeeks(structure, month, this.state.year);

      let datas = [];

      weeks.forEach((week, index) => {
        const label = `Sem. ${index + 1}`;

        datas.push({
          name: label,
          time: week.minutes
        });
      })

      that.setState({ historic, structureStats, weeks, datas })

      return;
    })
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row container m-auto mt-4 p-4 bg-white">
          <div className="col-lg-12 text-center">
            <Spinner animation="border" variant="primary" />
          </div>
        </div>  
      )
    }

    if (isEmpty(this.state.structureStats)) {
      return (
        <React.Fragment>
          <div className="row box m-auto mt-4 text-left">
            <div className="box-body">
                <h1 className="h4 mb-0 col-lg-12">Statistiques</h1>
            </div>    
          </div>    
          <div className="row m-auto container bg-white p-4 mt-2 text-left radius-10">            
            <div className="row mt-4 mb-4">
              <div className="col-lg-12 text-center">
                <h2>Aucune donnée à afficher</h2>
              </div>
            </div>
          </div>
      </React.Fragment>  
      )
    }

    const date                = new Date(this.state.year, this.state.month -1 , 10);  // 2009-11-10
    const month               = date.toLocaleString('fr-FR', { month: 'long' });
    const { structureStats }  = this.state;
    const firstname           = !isNull(structureStats.structure.firstname) ? structureStats.structure.firstname : "";
    const lastname            = !isNull(structureStats.structure.lastname) ? structureStats.structure.lastname : "";
    const company             = !isNull(structureStats.structure.name) ? structureStats.structure.name : "";

    return (
      <React.Fragment>
        <div className="row box m-auto mt-4 text-left">
          <div className="box-body">
              <h1 className="h4 mb-0 col-lg-12">Statistiques</h1>
              <h4 className="h4 mb-0 text-muted">{firstname} {lastname} <span className="text-400">/ {company}</span></h4>
          </div>  
        </div>
        <div className="row box">
          <div className="spaceBetween box-body col-lg-12">
            <h3>{month} {this.state.year}<small className="text-muted"> - {workedTimeRender(structureStats.month_work)}{structureStats.week_target > 0 && <span> sur {workedTimeRender(this.state.weeks.length  * structureStats.week_target)}</span>}</small></h3>
            <div>
              <Button variant="link" onClick={() => this.goToOtherMonth("previous")}>Précédent</Button>|
              <Button variant="link" onClick={() => this.goToOtherMonth("next")}>Suivant</Button>
            </div>
          </div>
        </div>
        <div className="row container text-left">
          <div className="col-lg-4 col-md-4 col-sm-4 box">
            <div className="box-body">
              <div className="row boxTitle boxTitleGreen">
                <div className="col-lg-12 text-center">
                  <h4>Aujourd'hui</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h1 className="mb-0 fw-light">{workedTimeRender(structureStats.today_work)}</h1>
                  {structureStats.week_target > 0 && <h4 className="text-muted mt-0"> sur {workedTimeRender(structureStats.day_target)}</h4>}
                </div>
              </div>
            </div>   
          </div>
          <div className={`${structureStats.week_target > 0 ? "col-lg-4 col-md-4 col-sm-4" : "col-lg-4 col-md-4 col-sm-4"} box`}>
            <div className="box-body">
              <div className="row boxTitle boxTitleRed">
                <div className="col-lg-12 text-center">
                  <h4>Cette semaine</h4>
                </div>
              </div>
              <div className="row">    
                <div className={`${structureStats.week_target > 0 ? "col-lg-6" : "col-lg-12"} text-left detailsMonthWorked`}>
                  <h1 className="mb-0 fw-light">{workedTimeRender(structureStats.week_work)}</h1>
                  {structureStats.week_target > 0 &&<h4 className="text-muted mt-0"> sur {workedTimeRender(structureStats.week_target)}</h4>}
                </div>
                {structureStats.week_target > 0 && <div className="col-lg-6 detailsMonthWorked">
                  <h1 className="mb-0 fw-light">{workedTimeRender(structureStats.week_target - structureStats.week_work)}</h1>
                  <h4 className="text-muted mt-0">Écart type</h4>
                </div>}
              </div> 
            </div>           
          </div>
          <div className="col-lg-4 col-md-4 col-sm-4 box">
            <div className="box-body">
              <div className="row boxTitle boxTitleBlue">
                <div className="col-lg-12 text-center">
                  <h4>Ce mois-ci</h4>
                </div>
              </div>
              <div className="row">    
                <div className="col-lg-12 text-center detailsMonthWorked">
                  <h1 className="mb-0 fw-light">{workedTimeRender(structureStats.month_work)}</h1>
                  {structureStats.week_target > 0 && <h4 className="text-muted mt-0"> sur {workedTimeRender(this.state.weeks.length  * structureStats.week_target)}</h4>}
                </div>
              </div>
            </div>          
          </div>
        </div>
        <div className="row container mt-2 text-left box"> 
          <div className="col-lg-6 box box-body text-left mt-2 detailsMonthWorked">
            <ResponsiveContainer width="100%" height="100%" className="mt-3">
              <BarChart
                width={500}
                height={300}
                data={this.state.datas}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis dataKey="name" />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey='time' fill="#0074D9" />
              </BarChart>
            </ResponsiveContainer>
          </div> 
          <div className="col-lg-6 text-left mt-2 box-body">
            <table className="table mt-3 table-striped">
              <tbody>
                {
                  this.state.weeks.map((week, index) => {
                    const todayDate = new Date().toISOString().slice(0, 10);

                    return (
                      <tr key={index}>
                        <td>
                          Semaine {index + 1} 
                          {" "}(du <Moment locale="fr" format='DD'>{week.start}</Moment>{" au "}
                          <Moment locale="fr" format='DD MMM'>{week.end}</Moment>)
                        </td>
                        <td>
                          <span className={`${week.start <= todayDate ? week.minutes >= structureStats.week_target ? "text-success fw-bold" : "text-danger fw-bold" : "text-muted" }`}>{workedTimeRender(week.minutes)}</span></td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
          <div className="col-lg-3 text-left">
            
          </div>
        </div>
        <div className="row container mt-2 text-left box">
          <div className="box-body">
            <h3>Dans le détail...</h3>
            <div className="col-lg-12 text-left mt-2">
              <table className="table table-hover table-striped">
                <thead>
                  <th>Date et heure</th>
                  <th>Pause</th>
                  <th>Actions</th>
                </thead>
                <tbody>
                  {
                    this.state.historic.map((hist, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Moment locale="fr" format='DD'>{hist.start}</Moment>{" "}
                            <Moment locale="fr" format='MMM'>{hist.start}</Moment>{" de "}
                            <Moment locale="fr" format='HH:mm'>{hist.start}</Moment>{" à "}
                            <Moment locale="fr" format='HH:mm'>{hist.end}</Moment>{" "}
                            {hist.overtime === "1" && <span className="badge rounded-pill bg-primary">heure supp.</span>}
                          </td>
                          <td>
                            {hist.breakTime}
                          </td>
                          <td>
                            <button className="btn btn-link" value={hist.id} onClick={this.handleDelete}>supprimer</button>
                            <button className="btn btn-link" value={hist.id} onClick={() => this.props.history.push('/work/' + hist.id + '/edit')}>modifier</button>
                          </td>
                        </tr>  
                      );
                    })
                  }
                </tbody> 
              </table>
            </div>
          </div>
        </div>
        <div className="row container mt-2 text-left box">          
          <div className="col-lg-12 text-right">
            <button type="button" className="btn btn-primary" onClick={(e) => {
              e.preventDefault();
              
              return this.props.history.push('/dashboard');
            }}>Retourner à l'accueil</button>
          </div>
        </div>
      </React.Fragment>  
    );
  }
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="box">
        <p className="label">{`${label} : ${workedTimeRender(payload[0].value)}`}</p>
      </div>
    );
  }

  return null;
};

export default withRouter(Settings);