import { fetchApi } from "./api"

export const getLogs = async (column, order) => {
    return fetchApi('/logs.php', 'GET', {
        q: 'all',
        column: column,
        order: order
    });
}

export const createLog = async (type, work_id) => {
    return fetchApi('/logs.php', 'POST', {
        q: 'create',
        type: type,
        work_id: work_id
    });
}