import WorkForm from '../forms/WorkForm';

const AddWorkModal = ({ toggleModal }) => {
    return (
        <div id="addWorkModal">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content" style={{ background: "#edf0f7" }}>
                    <WorkForm toggleModal={toggleModal} />
                </div>
            </div>
        </div>
    );
}

export default AddWorkModal;